<template>
  <p>0.3.2-Mon Mar 21 2022 20:01:48 GMT+0530 (India Standard Time)</p>
</template>

<script>
export default {
  name: "Version"
}
</script>

<style scoped>

</style>
